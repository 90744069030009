import request from '@/utils/request'

// 查询达人话题绑定管理列表
export function listProblemTopicCreater(query) {
  return request({
    url: '/work/ProblemTopicCreater/list',
    method: 'get',
    params: query
  })
}

// 查询达人话题绑定管理详细
export function getProblemTopicCreater(id) {
  return request({
    url: '/work/ProblemTopicCreater/' + id,
    method: 'get'
  })
}

// 新增达人话题绑定管理
export function addProblemTopicCreater(data) {
  return request({
    url: '/work/ProblemTopicCreater',
    method: 'post',
    data: data
  })
}

// 修改达人话题绑定管理
export function updateProblemTopicCreater(data) {
  return request({
    url: '/work/ProblemTopicCreater/update',
    method: 'post',
    data: data
  })
}

// 删除达人话题绑定管理
export function delProblemTopicCreater(id) {
  return request({
    url: '/work/ProblemTopicCreater/remove/' + id,
    method: 'get'
  })
}

// 导出达人话题绑定管理
export function exportProblemTopicCreater(query) {
  return request({
    url: '/work/ProblemTopicCreater/export',
    method: 'get',
    params: query
  })
}